<template>
  <text-field
    v-bind="$props"
    v-on="listeners"
    :type="type"
    :append-icon="icon"
  >

    <template v-for="name in slots" :slot="name">
      <slot :name="name"/>
    </template>

    <template v-for="name in scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData"/>
    </template>

  </text-field>
</template>

<script>
import TextField from '../TextField';
import isStrongPassword from 'validator/lib/isStrongPassword';
import { componentProps } from '@/utils';
import config from './defaults';

const STRONG_PASSWORD_PROPS = {
  mediumScore: 25,
  strongScore: 50,
  returnScore: true
};

const defaultProps = {
  ...TextField.props,
  name: String,
  autocomplete: String,
  strongPassword: Boolean,
  strongPasswordProps: Object,
  showPassword: Boolean,
  swowIcon: {
    type: String,
    default: 'mdi-eye-outline'
  },
  hideIcon: {
    type: String,
    default: 'mdi-eye-off-outline'
  }
};

delete defaultProps.type;
delete defaultProps.appendIcon;

export default {
  name: 'PasswordField',
  inheritAttrs: false,
  components: { TextField },
  props: componentProps( defaultProps, config ),
  data() {
    return {
      slots: [
        'append',
        'append-outer',
        'prepend',
        'prepend-inner',
        'progress',
        'extend'
      ],
      scopedSlots: [
        'message',
        'counter'
      ],
      show: false,
      strongScore: null
    }
  },
  computed: {
    type() {
      if ( ! this.showPassword || ! this.show ) return 'password';
      return 'text';
    },
    icon() {
      if ( ! this.showPassword ) return null;
      return this.show ? this.swowIcon : this.hideIcon;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        'click:append': () => this.show = !this.show
      }
    }
  },
  watch: {
    showPassword( value ) {
      !value && ( this.show = false );
    },
    strongPassword( value ) {
      !value && ( this.strongScore = null );
    },
    strongScore( value ) {
      this.$emit( 'score', value );
    }
  },
  methods: {
    onInput( value ) {
      this.evalStrongPass( value );
      this.$emit( 'input', value );
    },
    evalStrongPass( value ) {
      if ( this.strongPassword ) {

        if ( ! value ) return this.strongScore = null;

        const props = { ...STRONG_PASSWORD_PROPS, ...this.strongPasswordProps };
        const score = isStrongPassword( value, props );

        var type;
        if ( score >= ( props.strongScore || 50 )) type = 'strong';
        else if ( score >= ( props.mediumScore || 25 )) type = 'medium';
        else type = 'soft';

        var percent = 100;
        if ( type == 'medium' ) percent = 50;
        else if ( type == 'soft' ) percent = 100/3;

        this.strongScore = { score, type, percent };
      }
    }
  },
};
</script>
