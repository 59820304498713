<template>
  <v-progress-linear
    v-if="show"
    class="password-level"
    :value="value ? value.percent : 0"
    :height="height"
    :absolute="absolute"
    :color="computedColor"
    bottom
  >
    <span class="overline">
      {{ computedText }}
    </span>
  </v-progress-linear>
</template>

<script>
import { VProgressLinear } from 'vuetify/lib';

export default {
  components: { VProgressLinear },
  props: {
    value: Object,
    height: {
      type: [ Number, String ],
      default: 20
    },
    absolute: {
      type: Boolean,
      default: true
    },
    strongColor: {
      type: String,
      default: 'success'
    },
    mediumColor: {
      type: String,
      default: 'warning'
    },
    softColor: {
      type: String,
      default: 'error'
    },
    strongText: {
      type: String,
      default: 'Contraseña fuerte'
    },
    mediumText: {
      type: String,
      default: 'Contraseña media'
    },
    softText: {
      type: String,
      default: 'Contraseña débil'
    }
  },
  computed: {
    show() {
      return this.value && this.value.score > 0;
    },
    computedText() {
      const score = this.value || {};
      return ( score.type == 'strong'
        ? this.strongText
        : score.type == 'medium'
          ? this.mediumText
          : this.softText
      ) || score.type;
    },
    computedColor() {
      const score = this.value || {};
      return score.type == 'strong'
        ? this.strongColor
        : score.type == 'medium'
          ? this.mediumColor
          : this.softColor;
    }
  }
}
</script>

<style>
.password-level .overline {
  font-size: 10px !important;
}
</style>
